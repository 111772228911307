import React from 'react';

import classNames from 'classnames';

import styles from './Column.css';
import { StrapiButton } from '../../../atoms/Buttons/StrapiButton/StrapiButton';
import { Panel } from '../../../models/Strapi';
import { Analytics } from '../../../services/Analytics/Analytics';
import FeatureList from '../FeatureList/FeatureList';

type ColumnProps = {
    panel: Panel;
    moduleScreenId: unknown;
    isTwoColumns?: boolean;
    mobileAltVersion?: boolean;
};

const Column = React.memo(({ panel, moduleScreenId, isTwoColumns, mobileAltVersion }: ColumnProps) => {
    const handleAnalytics = (moduleScreenId, ctaText, columnIndex, shortTitle) => {
        Analytics.trackEvent(
            Analytics.pageBuilderAdvantageAnalytics.columnModuleImpression({
                moduleScreenId,
                ctaText,
                columnIndex,
                shortTitle,
            })
        );
        Analytics.trackEvent(
            Analytics.pageBuilderAdvantageAnalytics.columnModuleClick({
                moduleScreenId,
                ctaText,
                columnIndex,
                shortTitle,
            })
        );
    };

    return (
        <span
            className={classNames(styles.panel, {
                [styles.mobileAltVersion]: mobileAltVersion,
            })}
        >
            <div
                className={classNames(styles.box, {
                    [styles.topPadding]: panel.savingLabel,
                    [styles.twoColumnsDesign]: isTwoColumns,
                })}
                style={{ color: panel.color }}
            >
                {panel.savingLabel && (
                    <p
                        className={styles.savingLabel}
                        style={{
                            color: panel.gradient?.textColor || 'currentColor',
                            background: `linear-gradient(314.74deg, ${panel.gradient?.color1} 12.84%, ${panel.gradient?.color2} 48.8%, ${panel.gradient?.color3} 84.98%)`,
                        }}
                    >
                        {panel.savingLabel}
                    </p>
                )}
                <p className={styles.title}>{panel.title}</p>
                <p className={styles.shortTitle}>{panel.shortTitle}</p>
                <p className={styles.price}>{panel.price}</p>
                <StrapiButton
                    action={panel.CTA.action}
                    gradient={panel.gradient}
                    label={panel.CTA.label}
                    url={panel.CTA.url}
                    analyticsCallback={() =>
                        handleAnalytics(moduleScreenId as any, panel.CTA.label, panel.columnIndex, panel.shortTitle)
                    }
                    twoColumnsDesign={isTwoColumns}
                    mobileAltVersion={mobileAltVersion}
                />
                {panel.bestDealLabel && <p className={styles.bestDealLabel}>{panel.bestDealLabel}</p>}
            </div>
            <FeatureList features={panel.features}></FeatureList>
        </span>
    );
});

export default Column;
